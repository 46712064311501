import Header from "../../../components/header/Header";
import { 
    Flex, Box, 
    Text, 
    Select,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    Button,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Tabs, TabList, TabPanels, Tab, TabPanel,
    Tooltip
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { HiOutlineUserGroup } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import useData from "../../../data/useData";
import moment from "moment";
import ChartDisplay from "./ChartDisplay";
import client from "../../../libs/client";
import IsDesktop from "../../../utils/IsDesktop";
import { BASE_URL } from "../../../helpers/constant";
import { Calendar } from "react-calendar";
import { DatePicker, Space } from 'antd';
import dayjs from 'dayjs'

import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'
import { BsDownload } from "react-icons/bs";
import exportToCSV from "../../../utils/ExportToCSV";

dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)
const { RangePicker } = DatePicker;

const Dashboard = () => {
  const navigate = useNavigate();
  const [from, setFrom] = useState(moment().startOf('month').format('YYYY-MM-DD'))
  const [to, setTo] = useState(moment().format('YYYY-MM-DD'))
  const [prevFrom, setPrevFrom] = useState(moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'))
  const [prevTo, setPrevTo] = useState(moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'))
  const [systemFrom, setSystemFrom] = useState(moment().startOf('month').format('YYYY-MM-DD'))
  const [systemTo, setSystemTo] = useState(moment().add(1, 'day').format('YYYY-MM-DD'))
  const [comparisonValue, setComparisonValue] = useState('month')
  const { data, refetch } = useData(`user-statistics?from=${systemFrom}&to=${systemTo}&includeExtras=offers,adverts`);
  const {data: metricsData, refetch: refetchMetrics, isLoading: isLoadingMetrics} =useData(`admins/metrics?from=${from}&to=${to}&prevFrom=${prevFrom}&prevTo=${prevTo}&category=${comparisonValue}`);
  const [offersCount, setOffersCount] = useState(data?.data?.offersCount);
  const [advertsCount, setAdvertsCount] = useState(data?.data?.advertsCount);
  const [foundersCount, setFoundersCount] = useState(data?.data?.totalFounders);
  const [investorsCount, setInvestorsCount] = useState(data?.data?.totalInvestors);
  const [expertsCount, setExpertsCount] = useState(data?.data?.totalExperts);
  const [foundersMetrics, setFoundersMetrics] = useState(metricsData?.data?.foundersMetrics)
  const [prevFoundersMetrics, setPrevFoundersMetrics] = useState(metricsData?.data?.prevFoundersMetrics)
  const [investorsMetrics, setInvestorsMetrics] = useState(metricsData?.data?.investorsMetrics)
  const [prevInvestorsMetrics, setPrevInvestorsMetrics] = useState(metricsData?.data?.prevInvestorsMetrics)
  const [expertsMetrics, setExpertsMetrics] = useState(metricsData?.data?.expertsMetrics)
  const [prevExpertsMetrics, setPrevExpertsMetrics] = useState(metricsData?.data?.prevExpertsMetrics)
  const [advertsMetrics, setAdvertsMetrics] = useState(metricsData?.data?.advertsMetrics)
  const [prevAdvertsMetrics, setPrevAdvertsMetrics] = useState(metricsData?.data?.prevAdvertsMetrics)
  const [offersMetrics, setOffersMetrics] = useState(metricsData?.data?.offersMetrics)
  const [prevOffersMetrics, setPrevOffersMetrics] = useState(metricsData?.data?.prevOffersMetrics)
  const [diff, setDiff] = useState('week')
  const isDesktop = IsDesktop()
  const [systemComparisonValue, setSystemComparisonValue] = useState('month')
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { isOpen: isAltOpen, onOpen: onAltOpen, onClose: onAltClose } = useDisclosure()
  const [tabIndex, setTabIndex] = useState(0)


  const handleTabsChange = (index) => {
    setTabIndex(index)
  }

  const onComparisonChange = (e) => {
    setComparisonValue(e.target.value)
  }

  const onSystemComparisonChange = (e) => {
    setSystemComparisonValue(e.target.value)
  }

  useEffect(() => {
    if (data?.data) {
      setFoundersCount(data?.data?.totalFounders);
      setInvestorsCount(data?.data?.totalInvestors);
      setExpertsCount(data?.data?.totalExperts);
      setOffersCount(data?.data?.offersCount)
      setAdvertsCount(data?.data?.advertsCount)
    } else {
      refetch();
    }
  }, [data, refetch]);

  useEffect(() => {
    if(comparisonValue === 'week'){
        setFrom(moment().startOf('week').format('YYYY-MM-DD'))
        setTo(moment().format('YYYY-MM-DD'))
        setPrevFrom(moment().subtract(1, 'week').startOf('week').format('YYYY-MM-DD'))
        setPrevTo(moment().subtract(1, 'week').endOf('week').format('YYYY-MM-DD'))
    }else if(comparisonValue === 'month'){
        setFrom(moment().startOf('month').format('YYYY-MM-DD'))
        setTo(moment().format('YYYY-MM-DD'))
        setPrevFrom(moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'))
        setPrevTo(moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'))
    }else if(comparisonValue === 'year') {
        setFrom(moment().startOf('year').format('YYYY-MM-DD'))
        setTo(moment().format('YYYY-MM-DD'))
        setPrevFrom(moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD'))
        setPrevTo(moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD'))
    } else {
        onAltOpen()
    }
  }, [comparisonValue])

  useEffect(() => {
    // console.log("opening=======")
    if(systemComparisonValue === 'today'){
        setSystemFrom(moment().format('YYYY-MM-DD'))
        setSystemTo(moment().add(1, 'day').format('YYYY-MM-DD'))
    }else if(systemComparisonValue === 'week'){
        setSystemFrom(moment().startOf('week').format('YYYY-MM-DD'))
        setSystemTo(moment().add(1, 'day').format('YYYY-MM-DD'))
    }else if(systemComparisonValue === 'month'){
        setSystemFrom(moment().startOf('month').format('YYYY-MM-DD'))
        setSystemTo(moment().add(1, 'day').format('YYYY-MM-DD'))
    }else if(systemComparisonValue === 'year') {
        setSystemFrom(moment().startOf('year').format('YYYY-MM-DD'))
        setSystemTo(moment().add(1, 'day').format('YYYY-MM-DD'))
    } else {
        // console.log("opening=======")
        onOpen()
    }
  }, [systemComparisonValue])

  useEffect(() => {
    let _diff = moment(to).diff(from, 'day')
    if (_diff < 8){
        setDiff("day")
    } else if (_diff < 80){
        setDiff("week")
    } else if (_diff < 367){
        setDiff("month")
    } else if (_diff < 730){
        setDiff("quarter")
    } else {
        setDiff("year")
    }
    refetchMetrics()
  }, [from, to])

  useEffect(() => {
    if (metricsData?.data) {
      setPrevFoundersMetrics(metricsData?.data?.prevFoundersMetrics);
      setFoundersMetrics(metricsData?.data?.foundersMetrics);
      setPrevInvestorsMetrics(metricsData?.data?.prevInvestorsMetrics);
      setInvestorsMetrics(metricsData?.data?.investorsMetrics);
      setExpertsMetrics(metricsData?.data?.expertsMetrics);
      setPrevExpertsMetrics(metricsData?.data?.prevExpertsMetrics);
      setAdvertsMetrics(metricsData?.data?.advertsMetrics);
      setPrevAdvertsMetrics(metricsData?.data?.prevAdvertsMetrics);
      setPrevOffersMetrics(metricsData?.data?.prevOffersMetrics);
      setOffersMetrics(metricsData?.data?.offersMetrics);
    } else {
      refetch();
    }
  }, [metricsData, refetchMetrics]);

  const getSystemData = () => {
    // console.log("em from and to now======", systemFrom, systemTo)
    return client().get(`${BASE_URL}user-statistics?from=${systemFrom}&to=${systemTo}&includeExtras=offers,adverts`).then((res) => {
      const data = res?.data || {}
      if (data?.data) {
        setFoundersCount(data?.data?.totalFounders);
        setInvestorsCount(data?.data?.totalInvestors);
        setExpertsCount(data?.data?.totalExperts);
        setOffersCount(data?.data?.offersCount)
        setAdvertsCount(data?.data?.advertsCount)
      } 
    });
  }

  const downloadCSV = () => {
    const _data =  [
        ["From", "To", "Category", "Total"],
        [systemFrom, systemTo, "Founders", foundersCount],
        [systemFrom, systemTo, "Investors", investorsCount],
        [systemFrom, systemTo, "Experts", expertsCount],
        [systemFrom, systemTo, "Offers", offersCount],
        [systemFrom, systemTo, "Adverts", advertsCount],
    ];

    exportToCSV(_data, 'seedafounder.csv')
  }

  const onSystemCalendarChange = (date) => {
    // console.log("e=========", date)
    let dates = date.map((value) => moment(value?.$d ? value["$d"] : moment()).format("YYYY-MM-DD"))
    // console.log("enahces=========", dates)
    setSystemFrom(moment(dates[0]).isAfter(moment().subtract(1, 'day')) ?  moment().subtract(1, 'day').format("YYYY-MM-DD") : moment(dates[0]).format("YYYY-MM-DD"))
    setSystemTo(moment(dates[1]).isAfter(moment()) ?  moment().format("YYYY-MM-DD") : moment(dates[1]).format("YYYY-MM-DD"))

  } 

  const onCalendarChange = (date) => {
    // console.log("e=========", date)
    let dates = date.map((value) => moment(value?.$d ? value["$d"] : moment()).format("YYYY-MM-DD"))
    // console.log("enahces=========", dates)
    let _from = moment(dates[0]).isAfter(moment().subtract(1, 'day')) ?  moment().subtract(1, 'day').format("YYYY-MM-DD") : moment(dates[0]).format("YYYY-MM-DD")
    let _to = moment(dates[1]).isAfter(moment()) ?  moment().format("YYYY-MM-DD") : moment(dates[1]).format("YYYY-MM-DD")
    setFrom(_from)
    setTo(_to)
    let _diff = moment(to).diff(from, 'day')
    setPrevTo(moment(_from).subtract(1, 'day').format("YYYY-MM-DD"))
    setPrevFrom(moment(_from).subtract(_diff, 'day').format("YYYY-MM-DD"))
  } 


  useEffect(() => {
    getSystemData()
  }, [systemFrom, systemTo])

  // console.log("jbhjsd=====", metricsData)

  return (
    <Flex className="w-[100%] flex-col overflow-hidden">
      <Header title={"Users"} />
      <Flex className="w-[100%] gap-5 flex-col overflow-y-auto pb-8 md:pb-20" h={isDesktop ? "88vh" : "auto"} w="100%" p={4}  >
            <Flex className="flex-row gap-10 item-start xs:items-center justify-between w-full">
                <Flex className="flex-row gap-8 flex-wrap items-center">
                    <Text className="text-xl font-bold">
                        System Overview
                    </Text>
                    <Flex className="max-w-xl">
                        <Select value={systemComparisonValue} onChange={onSystemComparisonChange} placeholder=''>
                            <option value='today'>Today</option>
                            <option value='week'>This Week</option>
                            <option value='month'>This Month</option>
                            <option value='year'>This Year</option>
                            <option value='custom'>Custom</option>
                        </Select>
                    </Flex>
                    {
                        systemComparisonValue === "custom" && (
                            <Button colorScheme='blue' onClick={onOpen}>
                                Change custom selection
                            </Button>
                        ) 
                    }
                </Flex>
                <Tooltip label='Export as CSV'>
                    <Box onClick={downloadCSV} className="p-2 cursor-pointer max-h-9 border border-blue-100">
                        <BsDownload />
                    </Box>
                </Tooltip>
            </Flex>
        <Flex className="p-6 lg:p-10">
            <Box className="flex flex-wrap justify-center gap-[0.625rem]">
            <Box cursor="pointer" width="230px" height="10rem" p={4} borderWidth="0.0625rem" borderRadius="1.25rem" boxShadow="lg" box-shadow=".25rem 1.25rem 2.5rem rgba(232, 83, 62, 0.2)">
                <Flex className="flex-col justify-center gap-5 items-center">
                <Text>Founders</Text>
                <Text className="text-6xl">{foundersCount}</Text>
                </Flex>
            </Box>
            <Box cursor="pointer" width="230px" height="10rem" p={4} borderWidth="0.063rem" borderRadius="1.25rem" boxShadow="lg" box-shadow="1.25rem 1.25rem 2.5rem rgba(232, 83, 62, 0.2)">
                <Flex className="flex-col justify-center gap-5 items-center">
                <Text>Investors</Text>
                <Text className="text-6xl">{investorsCount}</Text>
                </Flex>
            </Box>
            <Box cursor="pointer" width="230px" height="10rem" p={4} borderWidth="0.063rem" borderRadius="1.25rem" boxShadow="lg"  box-shadow="1.25rem 1.25rem 2.5rem rgba(232, 83, 62, 0.2)" >
                <Flex className="flex-col justify-center gap-5 items-center">
                <Text>Experts</Text>
                <Text className="text-6xl">{expertsCount}</Text>
                </Flex>
            </Box>   
            <Box cursor="pointer" width="230px" height="10rem" p={4} borderWidth="0.063rem" borderRadius="1.25rem" boxShadow="lg"  box-shadow="1.25rem 1.25rem 2.5rem rgba(232, 83, 62, 0.2)" >
                <Flex className="flex-col justify-center gap-5 items-center">
                <Text>Offers</Text>
                <Text className="text-6xl">{offersCount}</Text>
                </Flex>
            </Box>          
            <Box cursor="pointer" width="230px" height="10rem" p={4} borderWidth="0.063rem" borderRadius="1.25rem" boxShadow="lg"  box-shadow="1.25rem 1.25rem 2.5rem rgba(232, 83, 62, 0.2)" >
                <Flex className="flex-col justify-center gap-5 items-center">
                <Text>Adverts</Text>
                <Text className="text-6xl">{advertsCount}</Text>
                </Flex>
            </Box>   
            </Box>
        </Flex>
        <Flex className="flex-col w-full overflow-hidden md:overflow-visible">
            <Flex  className="flex-col gap-8 w-full">
                <Flex className="flex-row gap-8 flex-wrap items-center">
                    <Text className="text-xl font-bold">
                        Data Comparison
                    </Text>
                    <Flex className="max-w-xl">
                        <Select value={comparisonValue} onChange={onComparisonChange} placeholder=''>
                            <option value='week'>Current vs Last week</option>
                            <option value='month'>Current vs Last month</option>
                            <option value='year'>Current vs Last year</option>
                            <option value='custom'>Custom</option>
                        </Select>
                    </Flex>
                    {
                        comparisonValue === "custom" && (
                            <Button colorScheme='blue' onClick={onAltOpen}>
                                Change custom selection
                            </Button>
                        ) 
                    }
                </Flex>
                <Box className="overflow-x-auto hide-scroll w-95vw md:w-full">
                <Tabs className="w-[90%] max-w-[1000px]" index={tabIndex} onChange={handleTabsChange}>
                    <TabList>
                    <Tab>Founders</Tab>
                    <Tab>Investors</Tab>
                    <Tab>Experts</Tab>
                    <Tab>Adverts</Tab>
                    <Tab>Offers</Tab>
                    </TabList>
                </Tabs>

                <Flex className="my-8 flex-col gap-1 text-sm italic">
                    <Text className="text-sm font-bold italic">
                        Legend
                    </Text>
                    <Flex className="flex-row flex-wrap gap-6">
                        <Flex className="flex-col gap-1">
                            <Flex className="flex-row items-center gap-3">
                                <Text>
                                Current Timeline
                                </Text>
                                <Box className="bg-blue-600 h-1 w-10">
                                </Box>
                            </Flex>
                            <Text className="text-xs">Range: {from.replace(/-/g, '/')} to {to.replace(/-/g, '/')}</Text>
                        </Flex>
                        <Flex className="flex-col gap-1">
                            <Flex className="flex-row items-center gap-3">
                                <Text>
                                Previous Timeline
                                </Text>
                                <Box className="bg-red-600 h-1 w-10">
                                </Box>
                            </Flex>
                            <Text className="text-xs">Range: {prevFrom.replace(/-/g, '/')} to {prevTo.replace(/-/g, '/')}</Text>
                        </Flex>
                    </Flex>
                </Flex>

                {
                    tabIndex == 0 ? (
                        <Box className="min-h-[300px] max-h-[400px] xl:max-h-[450px] xl:min-h-[380px] min-w-[700px] md:min-w-max relative w-full">
                            <ChartDisplay 
                                prevDataPoints={prevFoundersMetrics} 
                                currentDataPoints={foundersMetrics} 
                                type={diff}
                                from={from}
                                to={to}
                                prevFrom={prevFrom}
                                prevTo={prevTo}
                            />
                        </Box>
                    ) : null
                }

                {
                    tabIndex == 1 ? (
                        <Box className="min-h-[300px] max-h-[400px] xl:max-h-[450px] xl:min-h-[380px] min-w-[700px] md:min-w-max relative w-full">
                            <ChartDisplay 
                                prevDataPoints={prevInvestorsMetrics} 
                                currentDataPoints={investorsMetrics} 
                                type={diff}
                                from={from}
                                to={to}
                                prevFrom={prevFrom}
                                prevTo={prevTo}
                            />
                        </Box>
                    ) : null
                }
                {
                    tabIndex == 2 ? (
                        <Box className="min-h-[300px] max-h-[400px] xl:max-h-[450px] xl:min-h-[380px] min-w-[700px] md:min-w-max relative w-full">
                            <ChartDisplay 
                                prevDataPoints={prevExpertsMetrics} 
                                currentDataPoints={expertsMetrics} 
                                type={diff}
                                from={from}
                                to={to}
                                prevFrom={prevFrom}
                                prevTo={prevTo}
                            />
                        </Box>
                    ) : null
                }
                {
                    tabIndex == 3 ? (
                        <Box className="min-h-[300px] max-h-[400px] xl:max-h-[450px] xl:min-h-[380px] min-w-[700px] md:min-w-max relative w-full">
                            <ChartDisplay 
                                prevDataPoints={prevAdvertsMetrics} 
                                currentDataPoints={advertsMetrics} 
                                type={diff}
                                from={from}
                                to={to}
                                prevFrom={prevFrom}
                                prevTo={prevTo}
                            />
                        </Box>
                    ) : null
                }

                {
                    tabIndex == 4 ? (
                        <Box className="min-h-[300px] max-h-[400px] xl:max-h-[450px] xl:min-h-[380px] min-w-[700px] md:min-w-max relative w-full">
                            <ChartDisplay 
                                prevDataPoints={prevOffersMetrics} 
                                currentDataPoints={offersMetrics} 
                                type={diff}
                                from={from}
                                to={to}
                                prevFrom={prevFrom}
                                prevTo={prevTo}
                            />
                        </Box>
                    ) : null
                }            
                </Box>
            </Flex>
        </Flex>
        <>
                <Modal isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent>
                    <ModalHeader>Select Custom Range</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Flex className="mb-5 flex-col gap-5 items-center">
                            <Flex className="w-full items-start flex-col gap-2">
                                <Text>Current Date Range: {systemFrom} to {systemTo}</Text>
                                <Text className="text-xs italic">N/B: Date format is YYYY-MM-DD</Text>
                            </Flex>
                            <RangePicker 
                                value={[dayjs(systemFrom), dayjs(systemTo)]} 
                                maxDate={dayjs()}
                                onCalendarChange={onSystemCalendarChange}
                                format="YYYY-MM-DD"
                            />
                            <ModalFooter>
                                <Button onClick={onClose} colorScheme='blue'>
                                    See Results
                                </Button>
                            </ModalFooter>
                        </Flex>
                    </ModalBody>
                    </ModalContent>
                </Modal>
        </>
        <>
                <Modal isOpen={isAltOpen} onClose={onAltClose}>
                    <ModalOverlay />
                    <ModalContent>
                    <ModalHeader>Select Custom Range</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Flex className="mb-5 flex-col gap-5 items-center">
                            <Flex className="w-full items-start flex-col gap-2">
                                <Text>Current Date Range: <strong>{from} to {to}</strong></Text>
                                <Text className="text-xs italic">N/B: Date format is YYYY-MM-DD</Text>
                            </Flex>
                            <RangePicker 
                                value={[dayjs(from), dayjs(to)]} 
                                maxDate={dayjs()}
                                onCalendarChange={onCalendarChange}
                                format="YYYY-MM-DD"
                            />
                            <ModalFooter>
                                <Button onClick={onAltClose} colorScheme='blue'>
                                    See Comparison Results
                                </Button>
                            </ModalFooter>
                        </Flex>
                    </ModalBody>
                    </ModalContent>
                </Modal>
        </>
      </Flex>
    </Flex>
  );
};

export default Dashboard;