import ComposeInternalLayout from "../components/HOC/ComposeInternalLayout";
import { expertsUrl } from "../url/urls";
import Dashboard from "../pages/experts/Dashboard/Dashboard";
import Founders from "../pages/investor/Dashboard/Dashboard";
import AccountSettings from "../pages/founder/Settings/AccountSettings.jsx";
import Connections from '../pages/experts/Connections/Connections';

export const expertsRoutes = [
    {
      title: 'Dashboard',
      path: expertsUrl.dashboard(),
      element: ComposeInternalLayout(Dashboard),
    },
    {
      title: 'Founders',
      path: expertsUrl.founders(),
      element: ComposeInternalLayout(Founders),
    },
    {
      title: 'Connections',
      path: expertsUrl.connections(),
      element: ComposeInternalLayout(Connections),
    },
    {
      title: 'Account Settings',
      path: expertsUrl.accountSettings(),
      element: ComposeInternalLayout(AccountSettings),
    }
]
