import { Box, Button, Flex, HStack, TableContainer, useDisclosure, Text, Table, Th, Tbody, Tr, Td, VStack, Avatar, Spinner, Thead } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Header from "../../../components/header/Header";
import SearchBar from "../../../components/searchbar/SearchBar";
import moment from "moment";
import { mutateFunction, useMutation } from "../../../libs/apis";
import { CustomToast } from "../../../components/toast/CustomToast";
import { useData } from "../../../data";
import IsDesktop from "../../../utils/IsDesktop";
import ViewUserDetails from "./ViewUserDetails";
import UserCountSection from "./UsersCountSection";
import { BsDownload } from "react-icons/bs";
import exportToCSV from "../../../utils/ExportToCSV";
import BrandButton from "../../../components/brandButton/BrandButton";

const DeletedUsers = () => {
    const { data, isLoading, refetch } = useData("admins/get-deletion-request");
    const [displayedListStatus, setDisplayedListStatus] = useState("all");
    const [displayedList, setDisplayedList] = useState([])
    const {isOpen, onToggle: onToggleUserDetails} = useDisclosure()
    const [selecteduser, setSelectedUser] = useState()
    const {mutate} = useMutation(mutateFunction);
    const {addToast} = CustomToast();
    const isDesktop = IsDesktop()

  const changeUserView = (advertStatus) => {
    setDisplayedListStatus(advertStatus);
  };
  const viewUser = (user) => {
    setSelectedUser(user);
    onToggleUserDetails();
  }

  const downloadCSV = () => {
    const __data = displayedList.map(item => {
      return [
        `${item.firstName} ${item.lastName}`, 
        item.email, 
        item.firstName === item.initiator ? "Self" : item.initiator ,
        moment(item.createdAt).format("DD/MM/YYYY [at] HH:mm"),
        item.confirmedAt ? moment(item.confirmedAt).format("DD/MM/YYYY [at] HH:mm") : ''
      ]
    })

    __data.unshift(["Profile", "Email", "Initiated By", "Initiated At", "Confirmed At"])

    exportToCSV(__data, 'seedafounder_deleted_users.csv')
  }

  const sortList = (advertActiveStatus) => {
    if (advertActiveStatus === "all") {
      return data?.data?.users;
    }
    return data?.data?.users?.filter((user) => advertActiveStatus === moment(user?.confirmedAt).isValid());
  };
  useEffect(() => {
    if (data?.data) {
      setDisplayedList(sortList(displayedListStatus));
    }
  }, [data, displayedListStatus]);

  return (
    <>
      <Box>
        <Header title="Deleted Users" />
        {isOpen && (<ViewUserDetails user={selecteduser} onToggle={onToggleUserDetails} />)}
        {!isOpen && (
        <>
          <Flex w="100%">
            <Box mt="20px" w="100%">
              <HStack w="80%" justifyContent="space-between" className="items-start flex-wrap gap-2">
                <UserCountSection totalUsers={data?.data?.totalUsers} totalDeleted={data?.data?.totalDeleted} totalRequested={data?.data?.totalRequested} />
                <BrandButton text="Download CSV" minW="140px" onClick={downloadCSV} />
              </HStack>
              <Flex w="100%" justify="space-between" alignItems="center">
                <SearchBar
                  searchType="deleted-user"
                  searchRoute="admins/search-deletion-request"
                  onSelectOption={(advert) => {
                      viewUser(advert)
                  }}
                 />
                <Flex alignItems="flex-start" pr={2} h="29px" w="67">
                  <Button bg={displayedListStatus === "all" ? "brand.primary" : "#FFFFFF"} color={displayedListStatus === "all" ? "#FFFFFF" : "brand.primary"} _hover={{ bg: "#134A70", color: "#FFFFFF" }} borderRadius="5px 0px 0px 5px" boxShadow="base" onClick={() => changeUserView("all")}>
                    All
                  </Button>
                  <Button bg={displayedListStatus === true ? "brand.primary" : "#FFFFFF"} color={displayedListStatus === true ? "#FFFFFF" : "brand.primary"} _hover={{ bg: "#134A70", color: "#FFFFFF" }} boxShadow="base" borderRadius="0px" onClick={() => changeUserView(true)}>
                    Deleted
                  </Button>
                  <Button bg={displayedListStatus === false ? "brand.primary" : "#FFFFFF"} color={displayedListStatus === false ? "#FFFFFF" : "brand.primary"} _hover={{ bg: "#134A70", color: "#FFFFFF" }} boxShadow="base" borderRadius="0px 5px 5px 0px" onClick={() => changeUserView(false)}>
                    Requested
                  </Button>
                </Flex>
              </Flex>
            </Box>
          </Flex>
          <Box
          className={`flex-col w-full overflow-x-auto ${
            isDesktop ? "max-h-[75vh] fixed mt-5 px-2 pb-32 overflow-y-scroll" : ""
          }`}
        >
          <TableContainer maxWidth={!isDesktop ? '100%' : { base : '100%' , lg: "75vw" , xl : "1000px" }} overflowX={"scroll"} mt="30px" borderRadius="10px">
            <Table variant="simple" size="sm" fontSize="12px">
              <Thead>
              <Tr>
                <Th>S/N</Th>
                <Th>Profile</Th>
                <Th>email</Th>
                <Th>Initiated By</Th>
                <Th>Initiated At</Th>
                <Th>Confirmed At</Th>
              </Tr>
              </Thead>
              {!isLoading ? (<Tbody>
                {displayedList?.map((item, index) => {
                  return (
                    <Tr
                      key={item.id}
                      className="cursor-pointer hover:bg-blue-50"
                      onClick={() => viewUser(item)}
                    >
                      <Td>
                        {index + 1}
                      </Td>
                      <Td>
                        {`${item.firstName} ${item.lastName}`}
                      </Td>
                      <Td>
                        {item.email}
                      </Td>
                      <Td>
                        {item.firstName === item.initiator ? "Self" : item.initiator}
                      </Td>
                      <Td>
                        {moment(item.createdAt).format("DD/MM/YYYY [at] HH:mm")}
                      </Td>
                      <Td>
                        {item.confirmedAt ? moment(item.confirmedAt).format("DD/MM/YYYY [at] HH:mm") : '-'}
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>):(<Spinner/>)}
            </Table>
          </TableContainer>
          </Box>
        </>)}
      </Box>
    </>
  );
};
export default DeletedUsers;
